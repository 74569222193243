import React from 'react'
import { Svg } from '../Icons.style'

const User = ({ color, width, height, type }) => {
  if (type === 'desktop') {
    return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0902 11.6201C15.1585 10.8014 13.7591 9.8741 11.8526 9.37961C13.0736 8.49219 13.8691 7.05322 13.8691 5.43164C13.8691 2.74198 11.6809 0.553711 8.99121 0.553711C6.30155 0.553711 4.11328 2.74198 4.11328 5.43164C4.11328 7.05322 4.90883 8.49219 6.12996 9.37961C4.22328 9.8741 2.82397 10.8014 1.89226 11.6201C1.04171 12.3674 0.553711 13.4485 0.553711 14.586V17.4287H17.4287V14.586C17.4287 13.4485 16.9407 12.3674 16.0902 11.6201ZM5.43164 5.43164C5.43164 3.46893 7.0285 1.87207 8.99121 1.87207C10.9539 1.87207 12.5508 3.46893 12.5508 5.43164C12.5508 7.39435 10.9539 8.99121 8.99121 8.99121C7.0285 8.99121 5.43164 7.39435 5.43164 5.43164ZM16.1104 16.1104H1.87207V14.586C1.87207 13.8277 2.19661 13.1076 2.76248 12.6104C3.94004 11.5758 5.97649 10.3425 8.99121 10.3425C12.0059 10.3425 14.0424 11.5758 15.2199 12.6104C15.7858 13.1076 16.1104 13.8277 16.1104 14.586V16.1104Z" fill="white"/>
    </svg>

  )
  }

  return (
    <Svg
      className="user-icon"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
      width={width || '21.069'}
      height={height || '24.618'}
      viewBox="0 0 21.069 24.618"
    >
      <path
        id="user"
        d="M9.534,9.9A5.448,5.448,0,1,0,4.086,4.448,5.448,5.448,0,0,0,9.534,9.9Zm3.814,2.187h-.711a7.409,7.409,0,0,1-6.206,0H5.721A5.722,5.722,0,0,0,0,17.8v1.771a2.044,2.044,0,0,0,2.043,2.043H17.026a2.044,2.044,0,0,0,2.043-2.043V17.8A5.722,5.722,0,0,0,13.348,12.084Z"
        transform="translate(1 2)"
        fill="none"
        stroke="#5a6679"
        strokeWidth="2"
      />
    </Svg>
  )
}

export default User
