import React, { useState } from 'react'
import {
  BlogContent,
  BlogTitle,
  BlogWrapper,
  Description,
} from 'style/Blog.style'
import * as S from 'components/Widgets/LoginModal/LoginModal.style'
import ErrorMessage from 'components/ErrorMessage'
import Button from 'components/Button'
import AffiliateService from 'services/AffiliateService'
import useAuth from 'store/AuthContext'
import api from 'services/api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const RegisterAffiliatePage = props => {
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const { userAuthenticated } = useAuth()

  const history = useHistory()

  const submitForm = e => {
    e.preventDefault()
    e.stopPropagation()
    setErrors({})
    setLoading(true)
    const form = new FormData(e.target)
    AffiliateService.register(form)
      .then(api.getData)
      .then(data => {
        if (data.token) {
          api.setToken(data.token)
        }
        return data
      })
      .then(res => {
        userAuthenticated().then(() => {
          history.push('/account/details?affiliate_success=true')
        })
      })
      .catch(err => {
        if (err?.response?.data?.errors) {
          setErrors(err.response.data.errors)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <BlogWrapper className="blog-page" withoutSidebar>
      <BlogContent>
        <BlogTitle>
          <h2>Citrus.ge პარტნიორად რეგისტრაცია</h2>
        </BlogTitle>
        <Description className="flex justify-center">
          <S.LoginModalForm
            onSubmit={submitForm}
            id="affiliate-register-form"
            className="flex flex-col w-full md:w-1/2"
          >
            <S.LabelWithInput className="gap-0">
              <p className="!mb-0">სახელი *</p>
              <S.LoginInput
                disabled={loading}
                type="text"
                name="name"
                placeholder="სახელი"
              />
              <ErrorMessage
                className="p-0"
                show={errors.name}
                message={errors.name}
              />
            </S.LabelWithInput>
            <S.LabelWithInput className="gap-0">
              <p className="!mb-0">გვარი *</p>
              <S.LoginInput
                disabled={loading}
                type="text"
                name="lastname"
                placeholder="გვარი"
              />
              <ErrorMessage
                className="p-0"
                show={errors.lastname}
                message={errors.lastname}
              />
            </S.LabelWithInput>
            <S.LabelWithInput className="gap-0">
              <p className="!mb-0">ტელეფონის ნომერი *</p>
              <S.LoginInput
                disabled={loading}
                type="text"
                name="phone_number"
                placeholder="ტელეფონის ნომერი"
              />
              <ErrorMessage
                className="p-0"
                show={errors.phone_number}
                message={errors.phone_number}
              />
            </S.LabelWithInput>
            <S.LabelWithInput className="gap-0">
              <p className="!mb-0">ელ-ფოსტა *</p>
              <S.LoginInput
                disabled={loading}
                type="email"
                name="email"
                placeholder="ელ-ფოსტა"
              />
              <ErrorMessage
                className="p-0"
                show={errors.email}
                message={errors.email}
              />
            </S.LabelWithInput>
            <S.LabelWithInput className="gap-0">
              <p className="!mb-0">პაროლი *</p>
              <S.LoginInput
                disabled={loading}
                type="password"
                name="password"
                placeholder="პაროლი"
              />
              <ErrorMessage
                className="p-0"
                show={errors.password}
                message={errors.password}
              />
            </S.LabelWithInput>
            <S.LabelWithInput className="gap-0">
              <p className="!mb-0">გაიმეორეთ პაროლი *</p>
              <S.LoginInput
                disabled={loading}
                type="password"
                name="password_confirmation"
                placeholder="გაიმეორეთ პაროლი"
              />
              <ErrorMessage
                className="p-0"
                show={errors.password_confirmation}
                message={errors.password_confirmation}
              />
            </S.LabelWithInput>
            <S.LabelWithInput className="gap-0">
              <p className="!mb-0">პირადი ნომერი *</p>
              <S.LoginInput
                disabled={loading}
                type="number"
                name="personal_id"
                placeholder="პირადი ნომერი"
              />
              <ErrorMessage
                className="p-0"
                show={errors.personal_id}
                message={errors.personal_id}
              />
            </S.LabelWithInput>
            <S.LabelWithInput className="gap-0">
              <p className="!mb-0">მისამართი *</p>
              <S.LoginInput
                disabled={loading}
                type="text"
                name="address"
                placeholder="ქალაქი, ქუჩა, ნომერი"
              />
              <ErrorMessage
                className="p-0"
                show={errors.address}
                message={errors.address}
              />
            </S.LabelWithInput>
            <p className="text-xs flex items-center gap-x-1">
              * რეგისტრაციის ღილაკზე დაჭერით, თქვენ ეთანხმებით საიტის{' '}
              <a className="!text-blue underline" href="#">
                წესებსა და პირობებს
              </a>
            </p>

            <div className="flex justify-center w-full">
              <Button
                disabled={loading}
                type="submit"
                color="orange"
                className="h-unset"
              >
                {loading ? 'იტვირთება...' : 'რეგისტრაცია'}
              </Button>
            </div>
          </S.LoginModalForm>
        </Description>
      </BlogContent>
    </BlogWrapper>
  )
}

export default RegisterAffiliatePage
