import Button from 'components/Button'
import { MobileView } from 'components/DeviceDetect'
import { WoundedArrow } from 'components/Icons'
import WhiteWarning from 'components/Icons/Warning/WhiteWarning'
import Input from 'components/Input'
import Loader from 'components/Loader'
import OrderSuccessConfirmationModal from 'components/Order/OrderSuccessConfirmationModal'
import Message from 'components/UI/Message'
import Warning from 'components/Widgets/Warning'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {
  onLandingSentConversion,
  onProductAddToCartEvent,
} from 'services/conversionevents'
import OrdersService from 'services/OrdersService'
import useAuth from 'store/AuthContext'
import useOrders from 'store/OrdersProvider'
import useProduct from 'store/SingleProductProvider'
import LandingProductHead from './LandingProductHead'
import LandingProductLeftSide, {
  CProductConditions,
} from './LandingProductLeftSide'
import {
  Hint,
  InfoContent,
  LabelWithInput,
  ProductContainer,
  ProductContent,
  ProductForm,
  ProductInfo,
} from './Product.style'

const Product = props => {
  const {
    product,
    product: { landing },
  } = useProduct()
  const { user, userAuthenticated } = useAuth()
  const history = useHistory()
  const [success, setSuccess] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})
  const [requestError, setRequestError] = useState(false)
  const { setSuccessMessage } = useOrders()

  const orderFormSubmitted = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    setLoading(true)
    setRequestError(false)
    onProductAddToCartEvent(product, {
      payMethod: 'cash',
    })
    OrdersService.placeLandingOrder(formData)
      .then(data => {
        setSuccess(true)
        setLoading(false)
        setSuccessMessage(
          'თქვენი მოთხოვნა წარმატებით დაფიქსირდა. ოპერატორი მალე დაგიკავიშრდებათ და გაგაცნობთ დეტალურ ინფორმაციას.'
        )
        onLandingSentConversion(product, {
          cgd: landing.isCgd,
          order_id: data.order_id,
        })
        if (data.next_step === 'profile') {
          history.push('/account/orders')
        } else if (data.next_step === 'confirm_number') {
          setResponse(data)
          setConfirmModal(true)
        }
        /* eslint-disable-next-line */
      })
      .catch(err => {
        console.log(err)
        setRequestError(true)
        setLoading(false)
      })
  }

  const onOrderConfirm = () => {
    userAuthenticated().then(() => {
      history.push('/account/orders')
    })
  }

  return (
    <ProductContainer>
      <LandingProductHead />
      <ProductContent>
        <LandingProductLeftSide />
        <ProductInfo>
          {/* <BrowserView>
            <WaringArea>
              <Warning
                color="yellow"
                description={
                  stock_status === 1
                    ? 'იჩქარეთ დარჩენილია მხოლოდ:'
                    : 'მარაგის რაოდენობა'
                }
                warning={stock_status === 1 ? '26 ცალი' : 'გადასამოწმებელია'}
              />
            </WaringArea>
          </BrowserView> */}
          <InfoContent>
            {landing.isCgd ? (
              <h3
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {product.price}₾
                <span className="old-price">
                  {product.old_price ||
                    Math.ceil((product.price * 1.3) / 10) * 10 - 1}
                  ₾
                </span>
                {/* <WoundedArrow /> */}
              </h3>
            ) : (
              <h3
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {product.installment_price}₾{' '}
                <span style={{ fontSize: 'smaller' }}>/თვეში</span>
                <span className="old-price">
                  {Math.ceil(product.installment_price * 1.2)}₾
                  <span style={{ fontSize: 'smaller' }}>/თვეში</span>
                </span>
                {/* <WoundedArrow /> */}
              </h3>
            )}
            <Divider />
            {loading ? (
              <div className="flex py-4">
                <Loader notFull visible={true} type="ThreeDots" />
              </div>
            ) : (
              <ProductForm onSubmit={orderFormSubmitted}>
                <input type="hidden" name="product_id" value={product.id} />
                <input
                  type="hidden"
                  name="landing_signature"
                  value={product.landing.signature}
                />
                <LabelWithInput className="name-label">
                  <span>სრული სახელი:</span>
                  <Input
                    style={{ borderRadius: '10px', borderColor: 'green' }}
                    name="name"
                    defaultValue={user.name}
                    component="custom"
                    placeholder="სახელი გვარი"
                  />
                </LabelWithInput>
                <LabelWithInput className="phone-label">
                  <span>ტელეფონის ნომერი:</span>
                  <Input
                    style={{ borderRadius: '10px', borderColor: 'green' }}
                    defaultValue={user.phone}
                    name="number"
                    component="custom"
                    type="number"
                    placeholder="5XX XXX XXX"
                  />
                </LabelWithInput>
                <Button
                  style={{ fontSize: '20px', fontWeight: 'bold' }}
                  color="orange"
                  disabled={success}
                >
                  სწრაფი ყიდვა
                </Button>
              </ProductForm>
            )}
            <Message
              show={requestError}
              type="error"
              message="შეცდომა განაცხადის დაფიქსირებისას, გთხოვთ შეავსეთ ყველა ველი სწორად"
            />
          </InfoContent>

          <Hint>
            <p className="text-xs flex items-center gap-x-1">
              <WhiteWarning fill="#000" />
              ღილაკზე დაჭერით თქვენ ეთანხმებით საიტის წესებსა და პირობებს
            </p>
            <div className="flex w-100 justify-center align-center my-4">
              <WoundedArrow color="black1" width="70.32" height="53.43" />
            </div>
            <Warning
              color={'green'}
              icon="check"
              iconSize={32}
              description={
                <span
                  style={{
                    fontSize: 'larger',
                  }}
                >
                  ღილაკზე დაჭერის შემდეგ ოპერატორი დაგირეკავთ და დეტალურად
                  გაგაცნობთ პირობებს
                </span>
              }
            />
          </Hint>
        </ProductInfo>
        <MobileView>
          <div className="p-4">
            <CProductConditions />
          </div>
        </MobileView>
      </ProductContent>
      <OrderSuccessConfirmationModal
        onNumberConfirm={onOrderConfirm}
        show={confirmModal}
        response={response}
      />
    </ProductContainer>
  )
}

export default Product

const Divider = () => {
  return (
    <div
      style={{
        height: '1px',
        background: 'lightgray',
        margin: '15px 0',
        width: '100%',
      }}
    />
  )
}
