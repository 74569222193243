import api from './api'

class AffiliateService {
  static generateLink = data => {
    return api.call('post', `/affiliate/create-link`, data).then(api.getData)
  }
  static storeVisit = data => {
    return api.call('post', `/affiliate/store-visit`, data).then(api.getData)
  }
  static fetchStats = data => {
    return api.call('post', `/affiliate/stats`, data).then(api.getData)
  }
  static register = data => {
    return api.call('post', `/affiliate/register`, data).then(api.getData)
  }
}

export default AffiliateService
