import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import ShowPassword from 'components/Icons/Eye/ShowPassword'
import HidePassword from 'components/Icons/Eye/HidePassword'
import Message from 'components/UI/Message'
import * as S from 'components/Widgets/LoginModal/LoginModal.style'
import React, { useState } from 'react'
import UserService from 'services/UserService'
import useAuth from 'store/AuthContext'

const LoginForm = () => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [, setNewPassword] = useState(false)
  const [restoring, setRestoring] = useState(false)
  const [restoreSuccess, setRestoreSuccess] = useState(false)
  const { userAuthenticated } = useAuth()
  const [passwordVisible, setPasswordVisible] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const loginFormSubmited = e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData(e.target)
    restoring
      ? UserService.sendNewPassword(formData).then(data => {
          setRestoreSuccess(true)
          setRestoring(false)
          setLoading(false)
          setNewPassword(true)
        })
      : UserService.login(formData)
          .then(data => {
            if (!data.success) return
            setLoading(false)
            userAuthenticated()
          })
          .catch(err => {
            err?.response?.data?.errors && setErrors(err.response.data.errors)
            setLoading(false)
          })
  }

  return (
    <S.LoginModalForm id="login-form" onSubmit={loginFormSubmited}>
      <S.LabelWithInput>
        <p>ტელეფონის ნომერი *</p>
        <S.LoginInput type="text" name="phone" placeholder="5XX XXX XXX" />
        <ErrorMessage show={errors.phone} message={errors.phone} />
      </S.LabelWithInput>
      {!restoring ? (
        <S.LabelWithInput htmlFor="password">
          <p>პაროლი *</p>
          <div style={{ position: 'relative' }}>
            <S.LoginInput
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              id="password"
              key="loginPassword"
              placeholder="შეიყვანეთ პაროლი"
              style={{ width: '100%' }}
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                zIndex: 111,
              }}
            >
              {passwordVisible ? <HidePassword /> : <ShowPassword />}
            </span>
          </div>
          <ErrorMessage show={errors.password} message={errors.password} />
        </S.LabelWithInput>
      ) : null}
      <Button color="orange" className="log-in" disabled={loading}>
        {restoring ? 'ახალი პაროლის მიღება' : 'შესვლა'}
      </Button>
      {restoreSuccess ? (
        <Message
          type="success"
          message="ახალი პაროლი გამოგეგზავნათ მითითებულ ნომერზე"
        />
      ) : null}
      {!restoring ? (
        <Button
          type="button"
          color="orange1"
          className="log-in"
          onClick={() => setRestoring(true)}
          disabled={loading}
        >
          პაროლის აღდგენა
        </Button>
      ) : null}
    </S.LoginModalForm>
  )
}

export default LoginForm
