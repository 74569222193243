import Button from 'components/Button'
import Input from 'components/Input'
import Modal, { ModalFooter } from 'components/Modal'
import OrderSuccessConfirmationModal from 'components/Order/OrderSuccessConfirmationModal'
import Message from 'components/UI/Message'
import readablePayMethod from 'helpers/readablePayMethod'
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { useHistory } from 'react-router'
import OrdersService from 'services/OrdersService'
import useAuth from 'store/AuthContext'
import useCart from 'store/CartProvider'
import useOrders from 'store/OrdersProvider'
import {
  MetaTable,
  OrderForm,
  OrderImage,
  OrderInfo,
  OrderItem,
  OrderLabelWithInput,
  OrderQuantity,
  Orders,
  OrderWarning,
  PurchaseButton,
} from './OrderModal.style'
import Minus from '../../Icons/Minus/Minus'
import Plus2 from '../../Icons/Plus2'
import Trash from '../../Icons/Trash'

const OrderModal = props => {
  const {
    orderModalOpen,
    setOrderModal,
    products,
    changeQuantity,
    deleteProduct,
    fetchCart,
    payMethod,
    meta,
    isInstallment,
  } = useCart()
  const { auth, setLoginModalShow, user, userAuthenticated } = useAuth()
  const history = useHistory()
  const [confirmModal, setConfirmModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})
  const [requestError, setRequestError] = useState(false)
  const { setSuccessMessage } = useOrders()
  const checkoutText = isInstallment ? 'განაცხადის გაგზავნა' : 'შეკვეთა'

  useEffect(() => {
    setTimeout(() => {
      const input = document.querySelector('#order-name-input')

      if (input) input.focus()
    }, 300)
  }, [orderModalOpen])

  const handleQuantityChange = e => {
    const val = e.value
    changeQuantity(e.name, val)
  }

  const placeOrder = () => {
    const form = document.querySelector('#checkout-order-form')
    let formData
    formData = form ? new FormData(form) : {}

    setLoading(true)
    setRequestError(false)

    OrdersService.placeCartOrder(formData)
      .then(data => {
        setOrderModal(false)
        setLoading(false)
        setSuccessMessage(
          'თქვენი მოთხოვნა წარმატებით დაფიქსირდა. ოპერატორი მალე დაგიკავიშრდებათ და გაგაცნობთ დეტალურ ინფორმაციას.'
        )
        fetchCart()

        if (data.next_step === 'profile') {
          history.push('/account/orders')
        } else if (data.next_step === 'redirect_to_pay' && data.redirect_url) {
          document.location.replace(data.redirect_url)
        } else if (data.next_step === 'confirm_number') {
          setResponse(data)
          setConfirmModal(true)
        }
      })
      .catch(() => {
        setRequestError(true)
        setLoading(false)
      })
  }

  const onOrderConfirm = () => {
    userAuthenticated().then(() => {
      history.push('/account/orders')
    })
  }

  return (
    <Fragment>
      <Modal
        show={orderModalOpen}
        onClose={() => setOrderModal(false)}
        title="შეუკვეთე ნივთი"
        modal="cart"
        contentClass="height-90"
        bodyClass="withScrollbar"
        style={{ zIndex: '1700' }}
      >
        <Message
          show={requestError}
          type="error"
          message="გთხოვთ სწორად შეავსოთ ყველა ველი"
        />
        {auth ? null : (
          <OrderForm className="flex flex-col" id="checkout-order-form">
            <div className="flex flex-col md:flex-row justify-between gap-y-4 gap-x-4">
              <OrderLabelWithInput className="w-full">
                <span>მყდიველის დასახელება</span>
                <Input
                  type="text"
                  id="order-name-input"
                  disabled={auth}
                  defaultValue={user.name}
                  name="name"
                  placeholder="სახელი/გვარი   "
                />
              </OrderLabelWithInput>
              <OrderLabelWithInput className="w-full">
                <span>ტელეფონის ნომერი</span>
                <Input
                  type="number"
                  name="number"
                  disabled={auth}
                  defaultValue={user.phone}
                  placeholder="მიუთითეთ ტელეფონის ნომერი"
                />
              </OrderLabelWithInput>
            </div>
          </OrderForm>
        )}
        {!auth ? (
          <OrderWarning>
            <p>
              მეტი სიმარტივისთვის გთხოვთ გაიაროთ
              <a
                href="#/"
                onClick={() => {
                  setOrderModal(false)
                  setLoginModalShow(true)
                }}
              >
                ავტორიზაცია
              </a>
            </p>
          </OrderWarning>
        ) : (
          <OrderWarning>
            <p>შეკვეთის დეტალები</p>
          </OrderWarning>
        )}
        <Orders>
          {products.length === 0 && (
            <OrderWarning>
              <p>კალათა ცარიელია</p>
            </OrderWarning>
          )}
          {products.map(c => {
            return (
              <OrderItem key={c.id} className="md:pr-8">
                <OrderImage>
                  <img src={c.image} alt="" />
                </OrderImage>
                <OrderInfo>
                  <h3>{c.name}</h3>
                  {c.cart_attrs.length ? (
                    <h5 className="chosen-attributes">
                      {c.cart_attrs.map((at, key) => {
                        return `${key !== 0 ? ', ' : ''}${at.attribute}: ${
                          at.value
                        }`
                      })}
                    </h5>
                  ) : null}

                  {!isInstallment ? <p>{c.price}₾</p> : null}
                  <OrderQuantity>
                    <Input
                      id={`${c.sku}input`}
                      type="number"
                      name={c.sku}
                      defaultValue={c.quantity}
                      onChange={handleQuantityChange}
                      disabled
                    ></Input>
                    <Button
                      className={'minus-quantity'}
                      onClick={() => {
                        const elem = document.getElementById(`${c.sku}input`)
                        if (elem.value <= 1) {
                          if (
                            window.confirm(
                              'ნამდვილად გსურთ ამ პროდუქტის წაშლა?'
                            )
                          ) {
                            deleteProduct(elem.name)
                          } else {
                            return
                          }
                        }
                        elem.value--
                        handleQuantityChange(elem)
                      }}
                    >
                      <Minus />
                    </Button>
                    <Button
                      className={'plus-quantity'}
                      onClick={() => {
                        const elem = document.getElementById(`${c.sku}input`)
                        elem.value++
                        handleQuantityChange(elem)
                      }}
                    >
                      <Plus2 />
                    </Button>

                    <Button
                      className="delete-button"
                      onClick={() => {
                        deleteProduct(c.sku)
                      }}
                    >
                      <Trash />
                    </Button>
                  </OrderQuantity>
                </OrderInfo>
              </OrderItem>
            )
          })}
        </Orders>
        <ModalFooter className="flex w-full flex-col modal-footer gap-y-4 bottom-0 left-0 right-0 bg-white">
          <MetaTable className="w-full">
            <tr>
              <td>გადახდის მეთოდი:</td>
              <td>{readablePayMethod(payMethod)}</td>
            </tr>
            {!isInstallment ? (
              <tr>
                <td>ღირებულება:</td>
                <td>{meta.price}₾</td>
              </tr>
            ) : null}
            {!isInstallment && (
              <tr>
                <td>მიტანის ღირებულება:</td>
                <td>{meta.delivery}₾</td>
              </tr>
            )}
            {isInstallment ? (
              <tr>
                <td>განვადების ფასი:</td>
                <td>
                  <span>{meta.installment_price}₾/თვეში</span>
                </td>
              </tr>
            ) : (
              <tr>
                <td className={'total-text'}>სულ:</td>
                <td>
                  <span className={'total-number'}>{meta.total}₾</span>
                </td>
              </tr>
            )}
          </MetaTable>
          <div className="flex w-full cart-buy-buttons flex-col  ">
            <PurchaseButton disabled={loading} onClick={placeOrder}>
              <p>{checkoutText}</p>
            </PurchaseButton>

            <Button
              className="continue-shopping"
              onClick={() => setOrderModal(false)}
            >
              შოპინგის გაგრძელება
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <OrderSuccessConfirmationModal
        onNumberConfirm={onOrderConfirm}
        show={confirmModal}
        response={response}
      />
    </Fragment>
  )
}

export default OrderModal
