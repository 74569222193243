import styled from 'styled-components'

export const CustomInput = styled.input`
  outline: none;
  box-shadow: none;
  height: 46px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.color.lightGrey};
  border-radius: 5px;
  font-size:  ${props => props.theme.font.size.sm};
  color: ${props => props.theme.color.black2};
  background: transparent;
  padding: 0px 15px;
  &:placeholder {
    color: ${props => props.theme.color.mediumGrey};
  }
  ${props => props.error ? `
    border-color: red !important;
  ` : ''}
  & + .error {
    color: red;
  }
  &:focus {
    border-color: ${props => props.theme.color.blue4};
  }

  @media screen and (min-width: 1100px) {
    min-width: 200px;
  }

`
export const FilterButton = styled.button`
    display: flex;
    font-size: 12px;
    border: 1px solid red;
    padding: 6px 12px;
    border: none;
    width: fit-content;
    border-radius: 5px;
    background: linear-gradient(270deg, #ff416c 0%, #ff4b2b 100%);
    color: white;
`

export const TextArea = styled.textarea`
  outline: none;
  box-shadow: none;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.color.lightGrey};
  border-radius: 23px;
  font-size:  ${props => props.theme.font.size.sm};
  color: ${props => props.theme.color.black2};
  background: transparent;
  padding: 16px;
  &:placeholder {
    color: ${props => props.theme.color.mediumGrey};
  }

  &:focus {
    border-color: ${props => props.theme.color.blue4};
  }

  @media screen and (min-width: 1100px) {
    min-width: 200px;
  }

`

export const InputWrapper = styled.div`
  position: relative;

  input {
    width: 100%;
    padding-right: 59px;
    background-color: white;
    overflow: hidden;
  }

  button {
    position: absolute;
    right: 0;
    width: 49px;
    height: 100%;
    border-radius: 0 5px 5px 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (min-width: 1100px) {
    input {
      background-color: transparent;
    }
  }

`

export const Label = styled.label`
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.color.black2};
  text-align: left;
  @media screen and (min-width: 1100px) {

  }
`

export const Checkbox = styled.i`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${props => props.theme.color.orange};
  border-radius: 4px;
  border: 2px solid ${props => props.theme.color.lightGrey4};
  svg {
    display: none;
  }
  @media screen and (min-width: 1100px) {

  }

`

export const CheckboxInput = styled(CustomInput)`
  &:checked + i {
    border-color: ${props => props.theme.color.red};
    svg {
      display: block;
    }

    &:before {
      display: block;
    }
  }
  &:disabled + i {
    border: 2px solid gray;
  }
  @media screen and (min-width: 1100px) {

  }

`

export const LabelWithInput = styled(Label)`
  display: flex;
  align-items: center;
  cursor: pointer;

  i {
    margin-right: 10px;
  }

  input {
    display: none;
  }
  @media screen and (min-width: 1100px) {

  }

`

export const Radio = styled.i`
  width: 16px;
  height: 16px;
  border: 2px solid ${props =>props.theme.color.lightGrey4};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: ${props => props.theme.color.red};
    display: none;
    border-radius: 100%;
  }
  @media screen and (min-width: 1100px) {

  }

`

export const FromToWrapper = styled.div`
  width: 211px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 1100px) {

  }

`

export const LabelInsideInput = styled.div`
  display: flex;
  position: relative;

  input {
    width: 100px;
    min-width: 100px;
    height: 45px;
    padding-right: 40px;
    padding-left: 12px;
    border-radius: 4px;
    border-color: ${props => props.theme.color.lightGrey4};
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  label {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
  }
  @media screen and (min-width: 1100px) {

  }

`
