import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import Input from 'components/Input'
import Modal from 'components/Modal'
import Message from 'components/UI/Message'
import { OrderLabelWithInput } from 'components/Widgets/OrderModal/OrderModal.style'
import React, { useState } from 'react'
import OrdersService from 'services/OrdersService'
import useAuth from 'store/AuthContext'

const OrderSuccessConfirmationModal = ({ show, onNumberConfirm, response }) => {
  const [confirmationError, setConfirmationError] = useState(false)
  const { auth } = useAuth()
  const [showRedirect, setShowRedirect] = useState(auth)

  const confirmFormSubmitted = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    setShowRedirect(false)
    OrdersService.confirmAuthCode(formData)
      .then(() => {
        setShowRedirect(true)
        if (!response.redirect_url) {
          onNumberConfirm()
        } else {
          window.location.href = response.redirect_url
        }
      })
      .catch(() => {
        setConfirmationError(true)
      })
  }

  return (
    <Modal title="შეკვეთა წარმატებით გაიგზავნა" show={show}>
      <form onSubmit={confirmFormSubmitted} className="flex flex-col">
        <input type="hidden" name="user_id" value={response.user_id} />
        <input type="hidden" name="order_id" value={response.order_id} />
        {!response.redirect_url ? (
          <Message
            type="success"
            message="განაცხადი წარმატებით გამოიგზავნა, ოპერატორი მალე დაგიკავშირდებათ"
          />
        ) : null}
        <OrderLabelWithInput className="mt-4">
          {!response.redirect_url ? (
            <span>
              იმისთვის რომ შეძლოთ თქვენი შეკვეთის მიმდინარეობისა და ისტორიის
              ნახვა, გთხოვთ, შეიყვანეთ თქვენს მიერ მითითებულ ნომერზე
              გამოგზავნილი კოდი. <br />
              <br />
              სხვა შემთხვევაში, შეკვეთა განთავსდება მომხმარებლის რეგისტრაციის
              გარეშე, ერთჯერად შეკვეთად.
            </span>
          ) : (
            <span>
              იმისათვის, რომ შეძლოთ ბარათით გადახდა, საჭიროა დაადასტუროთ თქვენს
              მიერ მითითებული მობილურის ნომერი.
              <br />
              <br />
              გთხოვთ, შეიყვანოთ SMS-ის სახით მიღებული დადასტურების კოდი.
            </span>
          )}
          <Input
            style={{ border: '1px solid #FF416C' }}
            type="number"
            placeholder="მიუთითეთ SMS კოდი"
            name="confirm_code"
          />
          <ErrorMessage
            show={confirmationError}
            message="არასწორი ავტორიზაციის კოდი"
          />
        </OrderLabelWithInput>
        {response.order_url && !response.redirect_url ? (
          <p
            style={{ fontSize: '11px', marginBottom: '10px' }}
            className="mt-4 order-unique-url"
          >
            შეკვეთის უნიკალური ლინკი: &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="break-all"
              href={response.order_url}
            >
              {window.location.origin}
              {response.order_url}
            </a>
          </p>
        ) : null}
        <div
          style={{ justifyContent: 'space-between' }}
          className={`flex flex-col md:flex-row  mt-10`}
        >
          {response.redirect_url && showRedirect ? (
            <a href={response.redirect_url}>
              <Button type="button" disabled={auth} color="orange">
                გადახდა
              </Button>
            </a>
          ) : (
            <Button color="orange">ავტორიზაცია</Button>
          )}
          {response.invoice_url ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={response.invoice_url}
            >
              <Button
                style={{
                  backgroundColor: 'white',
                  color: '#5c6772',
                  border: '1px solid #5c6772',
                  width: '100%',
                  backgroundImage: 'none',
                  marginTop: '10px',
                }}
                type="button"
                disabled={auth}
                color="orange"
              >
                ინვოისის გადმოწერა
              </Button>
            </a>
          ) : null}
        </div>
      </form>
    </Modal>
  )
}

export const OrderDetailsSuccessConfirmationModal = ({
  show,
  onNumberConfirm,
  response,
}) => {
  const [confirmationError, setConfirmationError] = useState(null)
  const { auth } = useAuth()
  const [showRedirect, setShowRedirect] = useState(auth)

  const confirmFormSubmitted = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    setShowRedirect(false)
    OrdersService.confirmAuthCode(formData)
      .then(() => {
        setShowRedirect(true)
        setConfirmationError(false)
        if (!response.redirect_url) {
          onNumberConfirm()
        } else {
          window.location.href = response.redirect_url
        }
      })
      .catch(() => {
        setConfirmationError(true)
      })
  }

  return (
    <Modal title="შეკვეთის საბოლოო დადასტურება" show={show}>
      <form onSubmit={confirmFormSubmitted} className="flex flex-col">
        <input type="hidden" name="user_id" value={response.user_id} />
        <input type="hidden" name="order_id" value={response.order_id} />
        <Message
          show={confirmationError === false}
          type="success"
          message="ინფორმაცია შენახულია. გმადლობთ"
        />
        <OrderLabelWithInput className="mt-4">
          <span>
            დაადასტურეთ SMS კოდი, რომ თქვენი შეკვეთა დადასტურდეს და ნივთი
            დარეზერვდეს <br />
          </span>

          <Input
            style={{ border: '1px solid #FF416C' }}
            type="number"
            placeholder="მიუთითეთ SMS კოდი"
            name="confirm_code"
          />
          <ErrorMessage
            show={confirmationError}
            message="არასწორი ავტორიზაციის კოდი"
          />
        </OrderLabelWithInput>
        {response.order_url && !response.redirect_url ? (
          <p
            style={{ fontSize: '11px', marginBottom: '10px' }}
            className="mt-4 order-unique-url"
          >
            შეკვეთის უნიკალური ლინკი: &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="break-all"
              href={response.order_url}
            >
              {window.location.origin}
              {response.order_url}
            </a>
          </p>
        ) : null}
        <div
          style={{ justifyContent: 'space-between' }}
          className={`flex flex-col md:flex-row  mt-10`}
        >
          <Button color="orange">დადასტურება</Button>
        </div>
      </form>
    </Modal>
  )
}

export default OrderSuccessConfirmationModal
