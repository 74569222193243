import styled from 'styled-components'


export const BackToTopButton = styled.a`
 position:fixed;
  z-index: 32;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  right: 10px;
  bottom: 75px;
  background-color:#ff2337;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.4s ease;
  margin-bottom: 8px;

  &:hover{
    transform: scale(0.9);
    background-color: #ed1d30;
  }
  
  @media screen and (max-width: 559px){
    width: 54px;
    height: 54px;
    right: 5px;
    bottom: 65px;
  }
`

